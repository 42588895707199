<template>
  <div class="institucional">
    <div class="institucional-item institucional-item-sobre">
      <div class="container">
        <div class="institucional-content">
          <h2 class="institucional-title">Sobre <span>Nós</span></h2>
          <p>
            Somos uma startup, cuja missão é melhorar a qualidade, transparência, competitividade
            das compras públicas, através do maior conhecimento do servidor público e do uso de TI,
            gerando melhores resultados e refletindo nas ações de políticas públicas. Com visão de
            tornar-se a instituição modelo e reconhecida internacionalmente como parceira da
            Administração Pública pela atuação transparente, responsável e ética na busca pela
            melhoria constante dos processos de gestão e gastos públicos, a SELCORP apresenta ao
            mercado institucional soluções tecnológicas à Administração Pública Brasileira e
            Estrangeira.
          </p>

          <div class="institucional-video-container">
            <video class="institucional-video" controls>
              <source :src="fileServer('institucional.mp4', 'files')" type="video/mp4" />
            </video>
          </div>
        </div>

        <div class="institucional-image">
          <img :src="sobre" alt="Sobre nós" />
        </div>
      </div>
    </div>

    <div class="institucional-item institucional-item-missao">
      <div class="container">
        <div class="institucional-content">
          <h2 class="institucional-title mt-5">Nossa <span>Missão</span></h2>
          <p>
            Proporcionar a melhor tecnologia para utilização dos recursos públicos e segurança aos
            gestores públicos, melhorando a qualidade, a transparência, a competitividade nas
            compras públicas, transmitindo maior conhecimento ao servidor público, gerando melhores
            resultados, refletindo nas ações de políticas públicas do uso dos recursos financeiros,
            beneficiando a sociedade de forma abrangente.
          </p>
        </div>

        <div class="institucional-image">
          <img :src="missao" alt="Nossa missão" />
        </div>
      </div>
    </div>

    <div class="institucional-item institucional-item-valores">
      <div class="container">
        <div class="institucional-content">
          <h2 class="institucional-title mt-6">Nossos <span>Valores</span></h2>
          <p>
            Desenvolvimento de sistemas específicos de gestão pública e privada com as mais diversas
            funcionalidades de gestão de forma personalizada atendendo a necessidade e demanda do
            mercado global tendo como base:
          </p>
          <ul>
            <li><span>Ética</span></li>
            <li><span>Transparência</span></li>
            <li><span>Responsabilidade</span></li>
            <li><span>Legalidade</span></li>
            <li><span>Imparcialidade</span></li>
          </ul>
        </div>

        <div class="institucional-image">
          <img :src="valores" alt="Nossos valores" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sobre from '@/assets/images/sobre-nos.svg';
import missao from '@/assets/images/missao.svg';
import valores from '@/assets/images/valores.svg';

import { fileServer } from '@/utils/functions';

export default {
  data() {
    return {
      fileServer,
      sobre,
      missao,
      valores,
    };
  },
};
</script>

<style lang="scss" scoped>
input,
select,
:focus {
  outline: 0;
}
a,
button {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.25s ease-in-out 0s;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('~@/assets/fonts/gotham/gotham-htf-bold.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('~@/assets/fonts/gotham/gotham-htf-book.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('~@/assets/fonts/gotham/gotham-htf-light.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('~@/assets/fonts/gotham/gotham-htf-medium.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Thin';
  src: url('~@/assets/fonts/gotham/gotham-htf-thin.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

.institucional {
  &-item {
    display: flex;
    align-items: center;
    position: relative;

    .container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 50px 0;
      max-width: 90% !important;

      @media (min-width: 992px) {
        flex-direction: row;
        padding: 0;
        max-width: 95% !important;
      }
    }

    .institucional-video {
      height: auto;
      width: 90%;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    &-sobre {
      margin-bottom: 3rem;
    }

    &-missao {
      background: transparent linear-gradient(180deg, #319cda 0%, #00296a 100%) 0% 0% no-repeat;

      @media (min-width: 992px) {
        height: 90vh;
        padding-top: 0;
      }

      .institucional-content {
        order: 2;

        @media (min-width: 992px) {
          padding-left: 40px;
        }

        p {
          width: 100%;
        }
      }

      .institucional-title {
        order: 1;
      }

      .institucional-title,
      p {
        color: #fff;
      }
    }

    &-valores {
      overflow: hidden;

      @media (min-width: 992px) {
        height: 90vh;
        margin-bottom: 1rem;
      }

      &:before {
        content: '';
        background-color: #00296a;
        display: block;
        width: 1578px;
        height: 1244px;
        border-radius: 50%;
        position: absolute;
        top: -1180px;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: 768px) {
          top: -1200px;
        }

        @media (min-width: 992px) {
          top: -1170px;
        }
      }
    }
  }

  &-title {
    margin-bottom: 15px;
    font: 25px 'Gotham Medium';
    color: #000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.82px;

    @media (min-width: 992px) {
      font-size: 41px;
      margin-top: 40px;
      text-align: left;
    }

    span {
      padding-top: 9px;
      font: 32px 'Gotham Light';

      display: block;

      @media (min-width: 992px) {
        font-size: 53px;
      }
    }
  }

  &-content {
    flex: 1;

    p {
      width: 100%;
      margin-bottom: 15px;
      font: 16px/30px 'Gotham Light';
      letter-spacing: 0.4px;

      @media (min-width: 768px) {
        width: 100%;
      }

      @media (min-width: 992px) {
        width: 90%;
        font-size: 18px;
      }
    }

    ul {
      li {
        font: 16px/30px 'Gotham Light';
        letter-spacing: 0.4px;
        list-style-type: disc;
        list-style-position: inside;
        color: #00bae9;

        @media (min-width: 992px) {
          font-size: 16px;
        }

        span {
          color: #000;
        }
      }
    }
  }

  &-image {
    margin-top: 40px;
    flex: 1;
  }
}

.mt-6 {
  margin-top: 2rem;
}
</style>

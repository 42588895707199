<template>
  <div>
    <h2 class="title is-4">Minhas Licitações</h2>

    <div v-if="!data.length" class="panel">
      <div class="panel-block is-justify-content-center">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhuma licitação</p>
          </div>
        </section>
      </div>
    </div>

    <div v-if="data.length" class="panel">
      <div class="panel-block">
        <b-table
          class="table is-fullwidth table-typography-size"
          :data="data"
          :loading="tableLoading"
        >
          <b-table-column sortable field="type" label="Tipo" v-slot="props">
            <b-tooltip
              :label="props.row.type === 'dispensation' ? 'Dispensa' : 'Pregão'"
              :position="mobile ? 'is-left' : 'is-top'"
            >
              <b-icon
                class="custom-icon"
                type="is-primary"
                :icon="props.row.type === 'dispensation' ? 'alpha-d-box' : 'alpha-p-box'"
              ></b-icon>
            </b-tooltip>
          </b-table-column>

          <b-table-column
            centered
            sortable
            field="participation"
            label="Exclusiva"
            v-slot="props"
            :visible="displayExclusiveColumn"
          >
            <b-icon
              icon="check"
              size="is-medium"
              type="is-success"
              v-show="displayExclusiveIcon(props.row)"
            />
          </b-table-column>

          <b-table-column centered sortable label="Licitação" field="biddingNumber" v-slot="props">
            <router-link
              :to="{
                name: props.row.type === 'dispensation' ? 'Dispensa' : 'Pregao',
                params: { id: props.row.id },
              }"
            >
              {{ props.row.biddingNumber }}
            </router-link>
          </b-table-column>

          <b-table-column centered sortable field="buyerName" label="Comprador" v-slot="props">
            <div class="buyer-column">
              {{ props.row.buyerName }}
            </div>
          </b-table-column>

          <b-table-column centered sortable field="situationName" label="Situação" v-slot="props">
            {{ props.row.situationName }}
          </b-table-column>

          <b-table-column
            centered
            sortable
            field="object"
            label="Objeto"
            v-slot="props"
            :visible="!tableLoading && data.some(row => row.object)"
          >
            {{ props.row.object }}
          </b-table-column>

          <b-table-column
            centered
            sortable
            field="openDate"
            label="Previsão de Abertura"
            v-slot="props"
            :visible="!tableLoading && data.some(row => row.openDate)"
          >
            <div v-if="props.row.situationId === 17 && props.row.reopenDate">
              {{ props.row.reopenDate | formatDate }}
            </div>

            <div v-else>{{ props.row.openDate | formatDate }}</div>
          </b-table-column>

          <template v-slot:footer>
            <AppPagination
              :data-filters="{
                page: filters.page,
                perPage: filters.perPage,
              }"
              :number-lines="10"
              :total="count"
              @change-page="page => changePage(page)"
              @change-per-page="perPage => changePerPage(perPage)"
            />
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppPagination from '@/components/AppPagination';
import { formatarData as formatDate } from '@/utils/format';

export default {
  name: 'SellerMyBiddings',
  components: {
    AppPagination,
  },
  props: {
    count: { type: Number, default: 0 },
    data: { type: Array, default: () => [] },
    mobile: { type: Boolean, default: false },
    operateAuctionPermission: { type: Boolean, default: false },
    operateDispensationPermission: { type: Boolean, default: false },
    tableLoading: { type: Boolean, default: false },
  },
  data() {
    const filters = {
      page: 1,
      perPage: 10,
    };

    return {
      filters,
    };
  },
  filters: {
    formatDate,
  },
  computed: {
    ...mapState('companies', ['company']),
    displayExclusiveColumn() {
      return this.data.some(
        row =>
          row.participation === 'AMPLOCOTA' ||
          row.participation === 'RESERVADO' ||
          row.participation === 'EXCLUSIVA',
      );
    },
  },
  methods: {
    changePage(page) {
      this.filters.page = page;
      this.$emit('filter-seller-biddings', {
        page: this.filters.page,
        perPage: this.filters.perPage,
      });
    },
    changePerPage(perPage) {
      this.filters.page = Number(perPage) > Number(this.filters.perPage) ? 1 : this.filters.page;
      this.filters.perPage = perPage;
      this.$emit('filter-seller-biddings', {
        page: this.filters.page,
        perPage: this.filters.perPage,
      });
    },
    displayExclusiveIcon(row) {
      return (
        row.participation === 'AMPLOCOTA' ||
        row.participation === 'RESERVADO' ||
        row.participation === 'EXCLUSIVA'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.buyer-column {
  min-width: 150px;
}
</style>

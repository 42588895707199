/* eslint-disable import/prefer-default-export */
import { api } from '../http';

export const fetchBiddings = filters => {
  let params = '?';
  if (filters) {
    if (filters.situation) {
      params += `situation=${filters.situation}&`;
    }
    if (filters.page) params += `page=${filters.page}&`;
    if (filters.perPage) params += `per_page=${filters.perPage}`;
  }
  return api.get(`companies/${filters.companyId}/biddings${params}`);
};

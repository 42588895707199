<template>
  <div class="dashboard-container">
    <AppTitulo expand-title titulo="Dashboard" />

    <b-loading can-cancel is-full-page :active="loading.general"></b-loading>

    <section class="container is-fluid bidding-stats-container"><Bidding-Stats /></section>

    <section>
      <div class="container dashboard-table-container" v-if="company.buyer">
        <Buyer-My-Biddings
          :count="biddings.count"
          :data="biddings.data"
          :mobile="mobile"
          :operate-auction-permission="operateAuctionPermission"
          :operate-dispensation-permission="operateDispensationPermission"
          :tableLoading="loading.buyer"
          @filter-buyer-biddings="pagination => filterBuyerBiddings(pagination)"
        />

        <div class="indexed-finished-container is-flex">
          <Buyer-Indexed-Biddings
            :count="buyerIndexedBiddings.count"
            :data="buyerIndexedBiddings.data"
            :mobile="mobile"
            :operate-auction-permission="operateAuctionPermission"
            :operate-dispensation-permission="operateDispensationPermission"
            :tableLoading="loading.indexed"
            @filter-buyer-indexed-biddings="pagination => filterBuyerIndexedBiddings(pagination)"
          />

          <Buyer-Finished-Biddings
            :count="buyerFinishedBiddings.count"
            :data="buyerFinishedBiddings.data"
            :mobile="mobile"
            :tableLoading="loading.finished"
            @filter-buyer-finished-biddings="pagination => filterBuyerFinishedBiddings(pagination)"
          />
        </div>
      </div>

      <div class="container dashboard-table-container" v-if="company.seller">
        <Seller-My-Biddings
          :count="biddings.count"
          :data="biddings.data"
          :mobile="mobile"
          :operate-auction-permission="operateAuctionPermission"
          :operate-dispensation-permission="operateDispensationPermission"
          :tableLoading="loading.seller"
          @filter-seller-biddings="pagination => filterSellerBiddings(pagination)"
        />

        <Seller-Next-Biddings
          :count="nextBiddings.count"
          :data="nextBiddings.data"
          :mobile="mobile"
          :operate-auction-permission="operateAuctionPermission"
          :operate-dispensation-permission="operateDispensationPermission"
          :tableLoading="loading.next"
          @filter-seller-next-biddings="pagination => filterSellerNextBiddings(pagination)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppTitulo from '@/components/AppTitulo';

import { fetchBiddings } from '@/resources/companies/companiesBiddings';

import BiddingStats from './BiddingStats';
import BuyerFinishedBiddings from './BuyerFinishedBiddings';
import BuyerIndexedBiddings from './BuyerIndexedBiddings';
import BuyerMyBiddings from './BuyerMyBiddings';
import SellerMyBiddings from './SellerMyBiddings';
import SellerNextBiddings from './SellerNextBiddings';

export default {
  name: 'Dashboard',
  components: {
    AppTitulo,
    BiddingStats,
    BuyerFinishedBiddings,
    BuyerIndexedBiddings,
    BuyerMyBiddings,
    SellerMyBiddings,
    SellerNextBiddings,
  },
  data() {
    const biddings = {
      count: 0,
      data: [],
    };
    const buyerIndexedBiddings = {
      count: 0,
      data: [],
    };
    const buyerFinishedBiddings = {
      count: 0,
      data: [],
    };
    const nextBiddings = {
      count: 0,
      data: [],
    };
    const filters = {
      indexed: false,
      page: 1,
      perPage: 10,
      period: {
        start: null,
        end: null,
      },
      situation: '',
    };
    const loading = {
      buyer: false,
      finished: false,
      general: false,
      indexed: false,
      next: false,
      seller: false,
    };

    return {
      filters,
      biddings,
      buyerIndexedBiddings,
      buyerFinishedBiddings,
      loading,
      nextBiddings,
      ordination: (a, b) => a.situacao.id - b.situacao.id,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      user: 'getUser',
    }),
    operateAuctionPermission() {
      return this.user.empresas.some(
        companyUser => companyUser.id === this.company.id && companyUser.operar_ofertas,
      );
    },
    operateDispensationPermission() {
      return this.user.empresas.some(
        companyUser => companyUser.id === this.company.id && companyUser.operar_dispensas,
      );
    },
  },
  methods: {
    ...mapActions(['buscarStatsPregao', 'buscarStatsDispensa']),
    deleteBidding(id, type) {
      const index = this.buyerIndexedBiddings.data.findIndex(
        row => row.id === id && row.type === type,
      );
      if (index > -1) {
        this.buyerIndexedBiddings.data.splice(index, 1);
        this.buyerIndexedBiddings.count -= 1;
      }
    },
    async filterBuyerBiddings(pagination) {
      this.loading.buyer = true;

      const { data: response } = await fetchBiddings({
        companyId: this.company.id,
        situation: 'progress',
        ...pagination,
      });
      this.biddings.data = response.data;
      this.biddings.count = response.count;

      this.loading.buyer = false;
    },
    async filterBuyerFinishedBiddings(pagination) {
      this.loading.finished = true;

      const { data: response } = await fetchBiddings({
        companyId: this.company.id,
        situation: 'finished',
        ...pagination,
      });
      this.buyerFinishedBiddings.data = response.data;
      this.buyerFinishedBiddings.count = response.count;

      this.loading.finished = false;
    },
    async filterBuyerIndexedBiddings(pagination) {
      this.loading.indexed = true;

      const { data: response } = await fetchBiddings({
        companyId: this.company.id,
        situation: 'indexed',
        ...pagination,
      });
      this.buyerIndexedBiddings.data = response.data;
      this.buyerIndexedBiddings.count = response.count;

      this.loading.indexed = false;
    },
    async filterSellerNextBiddings(pagination) {
      this.loading.next = true;

      const { data: response } = await fetchBiddings({
        companyId: this.company.id,
        situation: 'can',
        ...pagination,
      });
      this.nextBiddings.data = response.data;
      this.nextBiddings.count = response.count;

      this.loading.next = false;
    },
    async filterSellerBiddings(pagination) {
      this.loading.seller = true;

      const { data: response } = await fetchBiddings({
        companyId: this.company.id,
        situation: 'participating',
        ...pagination,
      });
      this.biddings.data = response.data;
      this.biddings.count = response.count;

      this.loading.seller = false;
    },
    async MountTables() {
      const pagination = { page: this.filters.page, perPage: this.filters.perPage };

      if (this.company.id) {
        if (this.company.buyer) {
          await Promise.all([
            this.filterBuyerBiddings(pagination),
            this.filterBuyerFinishedBiddings(pagination),
            this.filterBuyerIndexedBiddings(pagination),
          ]);
        } else {
          await Promise.all([
            await this.filterSellerBiddings(pagination),
            await this.filterSellerNextBiddings(pagination),
          ]);
        }
      }
    },
  },
  async mounted() {
    this.loading.general = true;
    try {
      await Promise.all([this.MountTables(), this.buscarStatsPregao(), this.buscarStatsDispensa()]);
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss">
.bidding-stats-container {
  padding: 3rem;
}

.table-typography-size {
  font-size: 0.96rem;
}

.indexed-finished-container {
  margin-top: 3rem;
}

.dashboard-table-container {
  padding: 2rem 3rem 3rem;
}

@media (max-width: 1160px) {
  .indexed-finished-container {
    flex-direction: column;
  }
}
</style>

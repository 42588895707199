<template>
  <div>
    <div
      class="tile is-ancestor is-flex is-flex-wrap-wrap is-justify-content-center column-direction-dashboard"
    >
      <div class="tile is-parent card-dashboard">
        <article class="tile notification is-info is-child box is-flex card-text-dashboard">
          <span class="card-number">{{ auctionStats.inProgress }}</span>
          <div class="card-title">
            <p>{{ getAuctionText(auctionStats.inProgress) }} em <strong>Andamento</strong></p>
          </div>
        </article>
      </div>

      <div class="tile is-parent card-dashboard">
        <article class="tile notification is-primary is-child box is-flex card-text-dashboard">
          <span class="card-number">{{ dispensationStats.inProgress }}</span>
          <div class="card-title">
            <p>
              {{ getDispensationText(dispensationStats.inProgress) }} em <strong>Andamento</strong>
            </p>
          </div>
        </article>
      </div>

      <div class="tile is-parent card-dashboard">
        <article class="tile notification is-warning is-child box is-flex card-text-dashboard">
          <span class="card-number">{{ auctionStats.suspended }}</span>
          <div class="card-title">
            <p>{{ getAuctionText(auctionStats.suspended) }} <strong>Suspensos</strong></p>
          </div>
        </article>
      </div>

      <div v-if="company.buyer" class="tile is-parent card-dashboard">
        <article class="tile notification is-danger is-child box is-flex card-text-dashboard">
          <span class="card-number">{{ auctionStats.missingValidations }}</span>
          <div class="card-title">
            <p>
              {{ getAuctionText(auctionStats.missingValidations) }} sem
              <strong>Adjudicar/Homologar</strong>
            </p>
          </div>
        </article>
      </div>

      <div v-if="company.buyer" class="tile is-parent card-dashboard">
        <article class="tile notification is-danger is-child box is-flex card-text-dashboard">
          <span class="card-number">{{ dispensationStats.missingValidations }}</span>
          <div class="card-title">
            <p>
              {{ getDispensationText(dispensationStats.missingValidations) }} sem
              <strong>Ratificar</strong>
            </p>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'BiddingStats',
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      auctionStats: 'obterStatsPregoes',
      dispensationStats: 'obterStatsDispensas',
    }),
  },
  methods: {
    getAuctionText(counter) {
      return counter === 1 ? 'Pregão' : 'Pregões';
    },
    getDispensationText(counter) {
      return counter === 1 ? 'Dispensa' : 'Dispensas';
    },
  },
};
</script>

<style lang="scss" scoped>
.card-dashboard {
  max-width: fit-content;
}

.card-text-dashboard {
  align-items: center;
  border-radius: 25px;
  justify-content: center;
  max-width: 450px;
}

.card-number {
  flex: 1;
  font-size: 86px;
  text-align: center;
  padding-right: 25px;
  border-right: 1px #cdcdcd solid;
}

.card-title {
  flex: 2;
  font-size: 1.6rem;
  margin-left: 30px;
}

@media (min-width: 961px) and (max-width: 1214px) {
  .card-title {
    font-size: 24px;
  }

  .card-number {
    font-size: 70px;
  }
}

@media (max-width: 960px) {
  .card-dashboard {
    max-width: unset;
  }

  .card-text-dashboard {
    max-width: unset;
  }

  .column-direction-dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .card-title {
    font-size: 18px;
    margin-left: 15px;
  }

  .card-number {
    font-size: 44px;
    padding-right: 15px;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"title is-4"},[_vm._v("Minhas Licitações")]),(!_vm.data.length)?_c('div',{staticClass:"panel"},[_vm._m(0)]):_vm._e(),(_vm.data.length)?_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-block"},[_c('b-table',{staticClass:"table is-fullwidth table-typography-size",attrs:{"data":_vm.data,"loading":_vm.tableLoading},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('AppPagination',{attrs:{"data-filters":{
              page: _vm.filters.page,
              perPage: _vm.filters.perPage,
            },"number-lines":10,"total":_vm.count},on:{"change-page":function (page) { return _vm.changePage(page); },"change-per-page":function (perPage) { return _vm.changePerPage(perPage); }}})]},proxy:true}],null,false,3178738637)},[_c('b-table-column',{attrs:{"sortable":"","field":"type","label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":props.row.type === 'dispensation' ? 'Dispensa' : 'Pregão',"position":_vm.mobile ? 'is-left' : 'is-top'}},[_c('b-icon',{staticClass:"custom-icon",attrs:{"type":"is-primary","icon":props.row.type === 'dispensation' ? 'alpha-d-box' : 'alpha-p-box'}})],1)]}}],null,false,1343586477)}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"participation","label":"Exclusiva","visible":_vm.displayExclusiveColumn},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayExclusiveIcon(props.row)),expression:"displayExclusiveIcon(props.row)"}],attrs:{"icon":"check","size":"is-medium","type":"is-success"}})]}}],null,false,15695614)}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"biddingNumber","label":"Licitação"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
              name: props.row.type === 'dispensation' ? 'Dispensa' : 'Pregao',
              params: { id: props.row.id },
            }}},[_vm._v(" "+_vm._s(props.row.biddingNumber)+" ")])]}}],null,false,3994391338)}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"situationName","label":"Situação"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.situationName)+" ")]}}],null,false,1148214716)}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"object","label":"Objeto","visible":!_vm.tableLoading && _vm.data.some(function (row) { return row.object; })},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.object)+" ")]}}],null,false,557696936)}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"openDate","label":"Previsão de Abertura","visible":!_vm.tableLoading && _vm.data.some(function (row) { return row.openDate; })},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.situationId === 17 && props.row.reopenDate)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.reopenDate))+" ")]):_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(props.row.openDate)))])]}}],null,false,3546261576)}),_c('b-table-column',{attrs:{"centered":"","label":"Opções","visible":!_vm.tableLoading &&
              _vm.company.buyer &&
              (_vm.operateAuctionPermission || _vm.operateDispensationPermission) &&
              _vm.data.some(function (row) { return row.situationId < 4; })},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":"Editar"}},[(_vm.company.id === props.row.buyerId && props.row.situationId < 4)?_c('button',{staticClass:"button is-primary",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.editBidding(props.row.id, props.row.type)}}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1):_vm._e()])]}}],null,false,3820400636)})],1)],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-block is-justify-content-center"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v("Nenhuma licitação")])])])])}]

export { render, staticRenderFns }